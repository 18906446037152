import React, { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button';

const Administer = (isSuperUser) => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState("");
    const [pendingAffiliationRequests, setPendingAffiliationRequests] = useState([]);
    const [activeAffiliations, setActiveAffiliations] = useState([]);
    const [update, setUpdate] = useState(false);
    const organizationUuid = useRef();
    const affiliationUuid = useRef();

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        organizationUuid.current = queryParameters.get("organizationUuid");
        fetchData();
    }, [update]);

    const fetchData = async () => {
        try
        {
            let orgResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid.current, { withCredentials: true });
            setOrganization(orgResponse.data);
            let pendingAffiliationRequestsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/affiliations/pending/" + organizationUuid.current, 
                { withCredentials: true });
            setPendingAffiliationRequests(pendingAffiliationRequestsResponse.data);
            let activeAffiliations = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/affiliations/active/" + organizationUuid.current, 
                { withCredentials: true });
            setActiveAffiliations(activeAffiliations.data);
            setUpdate(false);
        }
        catch (error)
        {
            if (error.response.status === 404)
            {
                return
            }
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    function formatDate(timestampString) 
    {
        const options = { year: "numeric", month: "long", day: "numeric"}
        return new Date(timestampString).toLocaleDateString(undefined, options)
    }

    let handleAffiliationRequestApprove = async (e, requestUuid) => {
        e.preventDefault();
		try
        {
            let response = await axios.put(process.env.REACT_APP_BASE_API_URL + "/organization/affiliation/confirm/" + requestUuid, null, { withCredentials: true });
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
            window.scrollTo(0, 0);
        }
	}

    let handleAffiliationDelete = async (e, requestUuid, selfDelete) => {
        e.preventDefault();
		try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/organization/affiliation/delete/" + requestUuid, { withCredentials: true });
            setAlertVariant("primary");
            setMessage(response.data.message);
            affiliationUuid.current = "";
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
            window.scrollTo(0, 0);
        }
	}

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Administer Organization</title>
            </Helmet>
            <div className="p-2">
                <h6>{organization.name}</h6>
            </div>
            <div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className='Center-Text Margin-Bottom'>Organization</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <div className="Details Margin-Bottom">
                        <div>  
                            Name: {organization.name}
                        </div>      
                        { organization.description &&
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                Description: {organization.description}
                            </div>
                        }
                        <div>        
                            Telephone Number: {organization.telephoneNumber}
                        </div>
                        <div>      
                            Address Line 1: {organization.addressLine1}
                        </div>
                        
                        { organization.addressLine2 && 
                            <div>
                                Address Line 2: {organization.addressLine2}
                            </div>
                        }
                        <div>
                            City: {organization.city}
                        </div>
                        <div>
                            State/Province: {organization.stateProvince}
                        </div>
                        <div>
                            Postal Code: {organization.postalCode}
                        </div>
                        <div>
                            Country: {organization.country}
                        </div>
                        <div>
                            Approval Required to Affiliate: {organization.affiliationApprovalRequired ? "Yes" : "No"}
                        </div>                                    
                        <div className="d-flex flex-wrap">
                            {isSuperUser.isSuperUser &&
                                <>
                                    <div className="p-1">
                                        <Button as="a" href={"/organization/edit?organizationUuid=" + organizationUuid.current} 
                                            style={{ marginRight: "10px" }}>
                                            Edit
                                        </Button>
                                    </div>
                                    <div className="p-1">
                                        <Button as="a" href={"/qualification/list?organizationUuid=" + organization.uuid} 
                                            style={{ marginRight: "10px" }}>
                                            Qualifications
                                        </Button>
                                    </div>
                                    <div className="p-1">
                                        <Button as="a" href={"/requirement/list?organizationUuid=" + organization.uuid} 
                                            style={{ marginRight: "10px" }}>
                                            Requirements
                                        </Button>
                                    </div>
                                </>
                            }
                            <div className="p-1">
                                <Button as="a" href={"/evaluators?organizationUuid=" + organizationUuid.current} 
                                    style={{ marginRight: "10px" }}>
                                    Evaluators
                                </Button>
                            </div>
                            <div className="p-1">
                                <Button as="a" href={"/administrators?organizationUuid=" + organizationUuid.current} 
                                    style={{ marginRight: "10px" }}>
                                    Administrators
                                </Button>
                            </div>
                            <div className="p-1">
                                <Button as="a" href={"/organization/report?organizationUuid=" + organizationUuid.current + "&report=Expiration"} 
                                    style={{ marginRight: "10px" }}>
                                    Report
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="List Margin-Bottom">
                            <h4 className="Center-Text Margin-Bottom">Pending Affiliation Requests</h4>
                            { pendingAffiliationRequests.map((pending) => {
                                return(
                                    <div className="list-group" key={pending.uuid}>
                                        <div className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">{pending.user.surname}, {pending.user.givenName}</h5>
                                                <small>Requested: {formatDate(pending.requested)}</small>
                                            </div>
                                            <p className="mb-1">Registered: {formatDate(pending.user.registered)}</p>
                                            <p className="mb-1">E-mail: {pending.user.email}</p>
                                            { pending.user.organization &&
                                                <p className="mb-1">Organization: {pending.user.organization}</p>
                                            }
                                            <p className="mb-1">{pending.user.addressLine1}</p>
                                            { pending.user.addressLine2 &&
                                                <p className="mb-1">{pending.user.addressLine2}</p>
                                            }
                                            <p className="mb-1">{pending.user.city}, {pending.user.stateProvince} {pending.user.postalCode}</p>
                                            <p className="mb-1">{pending.user.country}</p>
                                            <p className="mb-1">Telephone: {pending.user.telephoneNumber}</p>
                                            <Button variant="success" onClick={(e) => handleAffiliationRequestApprove(e, pending.uuid)}
                                                style={{marginRight: "10px"}} type="submit">
                                                Approve
                                            </Button>
                                            <Button variant="danger" onClick={(e) => handleAffiliationDelete(e, pending.uuid, false)} type="submit">
                                                Deny
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })}
                            { pendingAffiliationRequests.length < 1 &&
                                <p className="Center-Text">There are no pending affiliation requests.</p>
                            }
                        </div>
                        <div className="List">
                            <h4 className="Center-Text Margin-Bottom">Active Affiliations</h4>
                            { activeAffiliations.map((active) => {
                                return(
                                    <div className="list-group" key={active.uuid}>
                                        <div className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">{active.user.surname}, {active.user.givenName}</h5>
                                                <small>Approved: {formatDate(active.confirmed)}</small>
                                            </div>
                                            <p className="mb-1">Registered: {formatDate(active.user.registered)}</p>
                                            <p className="mb-1">E-mail: {active.user.email}</p>
                                            { active.user.organization &&
                                                <p className="mb-1">Organization: {active.user.organization}</p>
                                            }
                                            <p className="mb-1">{active.user.addressLine1}</p>
                                            { active.user.addressLine2 &&
                                                <p className="mb-1">{active.user.addressLine2}</p>
                                            }
                                            <p className="mb-1">{active.user.city}, {active.user.stateProvince} {active.user.postalCode}</p>
                                            <p className="mb-1">{active.user.country}</p>
                                            <p className="mb-1">Telephone: {active.user.telephoneNumber}</p>
                                            <Button variant="danger" onClick={(e) => handleAffiliationDelete(e, active.uuid, false)} type="submit">
                                                Remove
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })}
                            { activeAffiliations.length < 1 &&
                                <p className="Center-Text">There are no active affiliations.</p>
                            }
                        </div>
                    </div>
                </div> 
            </div>     
        </HelmetProvider>
	);
};

export default Administer;
