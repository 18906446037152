import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const QualificationStatus = () => {  
	
	const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [qualificationVersion, setQualificationVersion] = useState("");
    const [organization, setOrganization] = useState("");
    const [showRemoveQualificationConfirmation, setShowRemoveQualificationConfirmation] = useState(false);
    const [qualificationRemoved, setQualificationRemoved] = useState(false);
    const [requirements, setRequirements] = useState([]);
    const [valid, setValid] = useState(false);
    const [viewer, setViewer] = useState("");
    const [user, setUser] = useState("");

    const holderUuid = useRef("");

    const handleCloseRemoveQualificationConfirmation = () => setShowRemoveQualificationConfirmation(false);
    const handleShowRemoveQualificationConfirmation = () => setShowRemoveQualificationConfirmation(true);

    function formatDate(timestampString) 
    {
        const options = { year: "numeric", month: "long", day: "numeric"}
        return new Date(timestampString).toLocaleDateString(undefined, options)
    }

	useEffect(() => {
        fetchData();
    }, []);

	const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
		    let qualificationVersionUuid = queryParameters.get("qualificationVersionUuid");
            holderUuid.current = queryParameters.get("holderUuid");
            let organizationUuid = queryParameters.get("organizationUuid");
            let viewer = queryParameters.get("viewer");
            setViewer(viewer);
            let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid, 
                { withCredentials: true });
            setOrganization(organizationResponse.data);
            let qualificationVersionResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/version/" + qualificationVersionUuid, 
                { withCredentials: true });
            setQualificationVersion(qualificationVersionResponse.data);
            let requirementsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/version/holder/" + 
                holderUuid.current + "?qualificationVersionUuid=" + qualificationVersionUuid, 
                { withCredentials: true });
            let sortedRequirements = requirementsResponse.data.requirements.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            setUser(requirementsResponse.data.holder);
            setRequirements(sortedRequirements);
            setValid(requirementsResponse.data.valid);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let handleRemoveQualification = async (e) => {
        e.preventDefault();
        try 
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/qualification/holder/delete/" + holderUuid.current, 
                { withCredentials: true });
            setMessage(response.data.message);
            setAlertVariant("primary");
            setQualificationRemoved(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            handleCloseRemoveQualificationConfirmation();
        }
    };

    if (qualificationRemoved)
    {
        return (
            <HelmetProvider>
                <Helmet>
                        <title>{ process.env.REACT_APP_APP_NAME } - Qualification Status</title>
                </Helmet>
                <div className="p-3 Center-Outer">
                    <div className="List Outer">
                        <h3 className="Center-Text">Qualification Status</h3>
                        {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    </div>
                </div>
            </HelmetProvider>
        )
    }
    else
    {
        return (
            <HelmetProvider>
                <Helmet>
                        <title>{ process.env.REACT_APP_APP_NAME } - Qualification Status</title>
                </Helmet>
                <div className="p-3 Center-Outer">
                    <div className="List Outer">
                        <h3 className="Center-Text">Qualification Status</h3>
                        {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                        <div className="Details Margin-Bottom">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            User:
                                        </td>
                                        <td>
                                            {user.surname}, {user.givenName} ({user.email})
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Qualification: </td>
                                        <td>{qualificationVersion.qualificationName}</td>
                                    </tr>
                                    {qualificationVersion.description &&
                                        <tr>
                                            <td>Description: </td>
                                            <td>{qualificationVersion.description}</td>
                                        </tr>
                                    } 
                                    <tr>
                                        <td>Version: </td>
                                        <td>{qualificationVersion.versionName}</td>
                                    </tr>
                                    <tr>
                                        <td>Organization: </td>
                                        <td>{organization.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Status: </td>
                                        <td>{valid ? "Valid" : "Invalid"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        { requirements.length > 0 &&
                            <div className="Margin-Bottom">
                                { requirements.map((requirement) => {
                                    return (
                                        <div className="list-group" key={requirement.qualificationVersionRequirementUuid}>
                                            <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{requirement.name}</h5>
                                                    <small>
                                                        {requirement.requirementType.name}
                                                    </small>
                                                </div>
                                                { requirement.description &&
                                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p className="mb-1">{requirement.description}</p>
                                                    </div>
                                                }
                                                { requirement.valid && requirement.endorsedBy &&
                                                    <div>
                                                        <p className="mb-1">Endorsed by {requirement.endorsedBy} on {formatDate(requirement.endorsed)}.</p>
                                                    </div>
                                                }
                                                { !requirement.valid && requirement.requirementType.id !== 2 &&
                                                    <div>
                                                        <p className="mb-1">Not Endorsed</p>
                                                    </div>
                                                }
                                                { requirement.valid && requirement.requirementType.id === 2 &&
                                                    <div>
                                                        <p className="mb-1">Valid</p>
                                                    </div>
                                                }
                                                { !requirement.valid && requirement.requirementType.id === 2 &&
                                                    <div>
                                                        <p className="mb-1">Invalid</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>  
                        }
                        {viewer === "self" &&
                            <div className="Form">
                                Remove this qualification from your portfolio.<br />
                                <Button variant="danger" onClick={handleShowRemoveQualificationConfirmation}>
                                    Remove Qualification
                                </Button><br/>
                            </div>
                        }
                    </div>
                    <Modal show={showRemoveQualificationConfirmation} onHide={handleCloseRemoveQualificationConfirmation}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Qualification Removal</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you certain you want to remove version {qualificationVersion.versionName} of {qualificationVersion.qualificationName} from your portfolio?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseRemoveQualificationConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={handleRemoveQualification}>
                            Remove Qualification
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>     
            </HelmetProvider>
        );
    }
}

export default QualificationStatus;