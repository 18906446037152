import React from "react";

import "./Organizations.css";

const OrganizationsList = ({setOrganizationsSearchResults,organizationsSearchResults,setOrganization,setOrganizationsInput}) => {

    const handleOrganizationSelect = async (org) => {
        if (org.description)
        {
            setOrganizationsInput(org.name + " - " + org.description);
        }
        else 
        {
            setOrganizationsInput(org.name);
        }
        setOrganizationsSearchResults([]);
        setOrganization(org);
    }

    if (organizationsSearchResults.length > 0)
    {
        return (
            <div className="affiliated-users-list">
                {organizationsSearchResults.map((result) => {
                    if (result.description)
                    {
                        return <div className="search-result" key={result.uuid} onClick={() => handleOrganizationSelect(result)}>
                                    {result.name} - {result.description}
                                </div>
                    }
                    else
                    {
                        return <div className="search-result" key={result.uuid} onClick={() => handleOrganizationSelect(result)}>
                                    {result.name}
                                </div>
                    }
                })}
            </div>
        );
    }
};

export default OrganizationsList;
