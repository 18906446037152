import Form from 'react-bootstrap/Form';

const SelectLanguage = ({language,setLanguage}) => {
    let languagesArray = [
        'English',
        'Amharic',
        'Amoy',
        'Arabic',
        'Armenian',
        'Azerbaijani',
        'Belarusian',
        'Bengali',
        'Bikol',
        'Bosnian',
        'Brazilian Portuguese',
        'Bulgarian',
        'Burmese',
        'Canadian French',
        'Cantonese',
        'Catalan',
        'Cebuano',
        'Cham',
        'Chamorro',
        'Chinese',
        'Chinese–Simplified',
        'Chinese–Traditional',
        'Chiu Chow',
        'Chow Jo',
        'Croatian',
        'Czech',
        'Danish',
        'Dari',
        'Dinka',
        'Dutch',
        'Estonian',
        'Faroese',
        'Farsi',
        'Fijian',
        'Finnish',
        'Flemish',
        'French',
        'French Creole',
        'Fukienese',
        'Georgian',
        'German',
        'Greek',
        'Gujarati',
        'Hainanese',
        'Haitian Creole',
        'Hakka',
        'Hebrew',
        'Hindi',
        'Hmong',
        'Hungarian',
        'Ibo',
        'Icelandic',
        'Ilocano',
        'Ilongo',
        'Indonesian',
        'Italian',
        'Japanese',
        'Kazakh',
        'Khmer',
        'Kinyarwanda',
        'Kirundi',
        'Kmhmu',
        'Korean',
        'Kurdish',
        'Laotian',
        'Latvian',
        'Lithuanian',
        'Macedonian',
        'Malay',
        'Malayalam',
        'Mandarin',
        'Mandinka',
        'Marathi',
        'Marshallese',
        'Mien',
        'Mongolian',
        'Navajo',
        'Nepali',
        'Norwegian',
        'Nuer',
        'Oromo',
        'Palauan',
        'Pampangan',
        'Pangasinan',
        'Pashtu',
        'Polish',
        'Portuguese',
        'Punjabi',
        'Quechua',
        'Romanian',
        'Russian',
        'Samoan',
        'Serbian',
        'Shanghainese',
        'Sherpa',
        'Shona',
        'Slovak',
        'Slovenian',
        'Somali',
        'Spanish',
        'Swahili',
        'Swedish',
        'Tagalog',
        'Taishanese',
        'Taiwanese',
        'Tamil',
        'Thai',
        'Tibetan',
        'Tigrigna',
        'Tongan',
        'Trukese',
        'Turkish',
        'Turkmen',
        'Ukrainian',
        'Urdu',
        'Uzbek',
        'Vietnamese',
        'Visayan',
        'Wolof',
        'Xhosa',
        'Yoruba',
        'Zulu'];
    
    return (
        <Form.Control as="select"
            value={language}
            name="language" 
            onChange={(e) => setLanguage(e.target.value)} >
                <option value="">
                    Select Language
                </option>
                {languagesArray.map((language) => {
                    return (
                        <option key={language} value={language}>
                            {language}
                        </option>
                    );
                })}
        </Form.Control>
    );
}

export default SelectLanguage;