import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

import './report.css'
import ExpirationReport from "./Expiration";

const Report = () => {  
	
	const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [organization, setOrganization] = useState("");
    const [loading, setLoading] = useState(false);
    const [noData, setNoData] = useState(false);

	useEffect(() => {
        const fetchData = async () => {
            try
            {
                const queryParameters = new URLSearchParams(window.location.search);
                let organizationUuid = queryParameters.get("organizationUuid");
                let report = queryParameters.get("report");
                setTitle(report);
                let orgResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid, { withCredentials: true });
                setOrganization(orgResponse.data);
            }
            catch (error)
            {
                console.log(error)
                setAlertVariant("danger");
                if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                    setMessage(error.response.data.message)
                } else {
                    setMessage("Unknown Error");
                }
            }
        };
        fetchData();
    }, []);

	
   
	return (
		<HelmetProvider>
			<Helmet>
					<title>{ process.env.REACT_APP_APP_NAME } - Expiration {title}</title>
			</Helmet>
            <div className="p-2">
                <h6><a href={"/administer?organizationUuid=" + organization.uuid }>{organization.name}</a>{' > '}
                Report{' > '}{title}</h6>
            </div>
			<div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">{title} Report</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                </div>
            </div>  
            <div className="Center-Outer">
                <div className="Report">
                    {title === 'Expiration' &&
                        <ExpirationReport
                            organizationUuid={organization.uuid}
                            setLoading={setLoading}
                            setNoData={setNoData}
                        />
                    }
                </div> 
                
            </div>
            {loading &&
                <h5 className="Center-Outer">
                    Loading...
                </h5>
            }
            {noData &&
                <h5 className="Center-Outer">
                    This report contains no data.
                </h5>
            }
		</HelmetProvider>
	);
}

export default Report;