import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ReactCountryDropdown from "react-country-dropdown";
import axios from 'axios';
import TelephoneNumber from "../../TelephoneNumber";
import OperationalJurisdictions from "../Authentication/OperationalJurisdictions";
import SelectLanguage from "../Authentication/SelectLanguage";
import {v4 as uuidv4} from 'uuid';
import { Link } from 'react-router-dom';

const Profile = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [additionalLanguageMessage, setAdditionalLanguageMessage] = useState("");
    const [givenName, setGivenName] = useState("");
    const [surname, setSurname] = useState("");
    const [telephoneNumber, setTelephoneNumber] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [organization, setOrganization] = useState("");
    const [city, setCity] = useState("");
    const [stateProvince, setStateProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("US");
    const [title, setTitle] = useState("");
    const [industryExperience, setIndustryExperience] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [employmentType, setEmploymentType] = useState("");
    const [naicsOptions, setNaicsOptions] = useState([]);
    const [naicsCodeId, setNaicsCodeId] = useState("");
    const [citizenship, setCitizenship] = useState("US");
    const [professionalOrganizations, setProfessionalOrganizations] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState("");
    const [education, setEducation] = useState("");
    const [preferredLanguage, setPreferredLanguage] = useState("");
    const [additionalLanguage, setAdditionalLanguage] = useState("");
    const [additionalLanguageProficiency, setAdditionalLanguageProficiency] = useState("");
    const [additionalLanguages, setAdditionalLanguages] = useState([]);
    const [operationalJurisdictions, setOperationalJurisdictions] = useState([]);
    const [ethnicity, setEthnicity] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);
    const [update, setUpdate] = useState(false);
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try
            {
                if (fetched)
                {
                    return;
                }
                let response = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + "/details", { withCredentials: true });
                let naicsResponse = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + '/naicsCodes/');
                const industryArray = [];
                naicsResponse.data.forEach((value) => {
                    industryArray.push({
                        key: value.definition,
                        value: value.id,
                    });
                });
                setNaicsOptions([{key: 'Select Industry', value: ''}, ...industryArray]);
                setGivenName(response.data.givenName);
                setSurname(response.data.surname);
                setOrganization(response.data.organization);
                setAddressLine1(response.data.addressLine1);
                if (response.data.addressLine2)
                {
                    setAddressLine2(response.data.addressLine2);
                }
                setCity(response.data.city);
                setStateProvince(response.data.stateProvince);
                setPostalCode(response.data.postalCode);
                setCountry(response.data.country);
                setTelephoneNumber(response.data.telephoneNumber);
                setDateOfBirth(response.data.dateOfBirth.slice(0, 10));
                setGender(response.data.gender);
                setEthnicity(response.data.ethnicity);
                setCitizenship(response.data.citizenship);
                setTitle(response.data.title);
                setEmploymentType(response.data.employmentType);
                setNaicsCodeId(response.data.naicsCodeId);
                setIndustryExperience(response.data.industryExperience.slice(0, 10));
                setLinkedIn(response.data.linkedIn);
                setPreferredLanguage(response.data.preferredLanguage);
                setEducation(response.data.education)
                setProfessionalOrganizations(response.data.professionalOrganizations);
                setOperationalJurisdictions(response.data.operationalJurisdictions);
                setAdditionalLanguages(response.data.additionalLanguages);
                setFetched(true);
            }
            catch (error)
            {
                console.log(error)
                setAlertVariant("danger");
                if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                    setMessage(error.response.data.message)
                } else {
                    setMessage("Unknown Error");
                }
            }
        };
        fetchData();
        setUpdate(false);
    }, [update, fetched]);

    function additionalLanguageExists(language) {
        let match = additionalLanguages.filter((l) =>
            l.language === language.language  
        );
        let exists = false;
        if (match.length > 0)
        {
            exists = true;
        }
        return exists;
    }

    let handleAddAdditionalLanguage = async (e) => {
        e.preventDefault();
        if (!additionalLanguage)
        {
            setAdditionalLanguageMessage("Please select a language.");
            return;
        }
        if (!additionalLanguageProficiency)
        {
            setAdditionalLanguageMessage("Please select a proficiency.");
            return;
        }
        let newLanguage = {
            language: additionalLanguage,
            proficiency: additionalLanguageProficiency
        };
        if (additionalLanguageExists(newLanguage))
        {
            return;
        }
        setAdditionalLanguages(additionalLanguages => [...additionalLanguages, newLanguage]);
        setAdditionalLanguageMessage("");
        setUpdate(true);
	}

    let handleRemoveAdditionalLanguage = async (e, language) => {
        e.preventDefault();
		const index = additionalLanguages.indexOf(language);
        if (index > -1) {
            additionalLanguages.splice(index, 1);
            setUpdate(true);
        }
	}

    let handleSubmit = async (e) => {
        e.preventDefault();

        setButtonClicked(true);

        const userInfo = {
            givenName: givenName,
            surname: surname,
            organization: organization,
            telephoneNumber: telephoneNumber,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateProvince: stateProvince,
            postalCode: postalCode,
            country: country,
            title: title,
            employmentType: employmentType,
            naicsCodeId: naicsCodeId,
            dateOfBirth: dateOfBirth,
            gender: gender,
            citizenship: citizenship,
            education: education,
            linkedInProfile: linkedIn,
            preferredLanguage: preferredLanguage,
            additionalLanguages: additionalLanguages,
            ethnicity: ethnicity,
            professionalOrganizations: professionalOrganizations,
            industryExperience: industryExperience,
            operationalJurisdictions: operationalJurisdictions
        };

        try 
        {
            let response = await axios.put(process.env.REACT_APP_BASE_AUTH_API_URL + '/update', userInfo, { withCredentials: true } );
            setAlertVariant("primary");               
            setMessage(response.data.message);
        }
        catch (error)
        {
            console.log(error)
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setButtonClicked(false);
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Profile</title>
            </Helmet>
            <div className="p-3 Center-Outer">
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center">
                                    <h3>User Profile</h3>
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center Outer">    
                                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center Margin-Bottom">
                                    <Form onSubmit={handleSubmit} className='Form'>
                                        <Form.Group className="mb-3" controlId="formGivenName">
                                            <Form.Label>First Name *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter First Name"
                                            value={givenName}
                                            name="givenName" 
                                            onChange={(e) => setGivenName(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formSurname">
                                            <Form.Label>Last Name *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Last Name"
                                            value={surname}
                                            name="surname" 
                                            onChange={(e) => setSurname(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formDate">
                                            <Form.Label>Date of Birth *</Form.Label>
                                            <Form.Control type="date" 
                                                defaultValue={dateOfBirth}
                                                name="date" 
                                                onChange={(e) => setDateOfBirth(e.target.value)} 
                                                required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formGender">
                                            <Form.Label>Gender *</Form.Label>
                                            <Form.Control as="select"
                                                value={gender}
                                                name="gender" 
                                                onChange={(e) => setGender(e.target.value)} 
                                                required>
                                                    <option value="">
                                                        Select Gender
                                                    </option>
                                                    <option value="F">
                                                        Female
                                                    </option>
                                                    <option value="M">
                                                        Male
                                                    </option>
                                                    <option value="O">
                                                        Other
                                                    </option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formEthnicity">
                                            <Form.Label>Ethnicity *</Form.Label>
                                            <Form.Control as="select"
                                                value={ethnicity}
                                                name="ethnicity" 
                                                onChange={(e) => setEthnicity(e.target.value)} 
                                                required >
                                                    <option value="">
                                                        Select Ethnicity
                                                    </option>
                                                    <option value="AIAN">
                                                        American Indian or Alaska Native
                                                    </option>
                                                    <option value="ASIAN">
                                                        Asian
                                                    </option>
                                                    <option value="BAA">
                                                        Black or African American
                                                    </option>
                                                    <option value="HISPANIC">
                                                        Hispanic
                                                    </option>
                                                    <option value="NHPI">
                                                        Native Hawaiian or Other Pacific Islander
                                                    </option>
                                                    <option value="WC">
                                                        White or Caucasian
                                                    </option>
                                                    <option value="MEO">
                                                        Multiple Ethnicity / Other
                                                    </option>
                                                    <option value="NONE">
                                                        I Choose Not to Answer
                                                    </option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formCitizenship">
                                            <Form.Label>Citizenship *</Form.Label><br />
                                            <ReactCountryDropdown
                                                defaultCountry={citizenship}
                                                onSelect={(e) => setCitizenship(e.code)}
                                                required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formEducation">
                                            <Form.Label>Highest Education Achieved *</Form.Label>
                                            <Form.Control as="select"
                                                value={education}
                                                name="education" 
                                                onChange={(e) => setEducation(e.target.value)} 
                                                required>
                                                <option value="">
                                                    Select Education
                                                </option>
                                                <option value="AD">
                                                    Associate's Degree
                                                </option>
                                                <option value="BD">
                                                    Bachelor's Degree
                                                </option>
                                                <option value="DD">
                                                    Doctoral Degree
                                                </option>
                                                <option value="ES">
                                                    Elementary School
                                                </option>
                                                <option value="HS">
                                                    High School Diploma or Equivalent
                                                </option>
                                                <option value="MS">
                                                    Middle School
                                                </option>
                                                <option value="SC">
                                                    Some College
                                                </option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formOrganization">
                                            <Form.Label>Organization</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Organization"
                                            value={organization}
                                            name="organization" 
                                            onChange={(e) => setOrganization(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formTitle">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Title"
                                            value={title}
                                            name="title" 
                                            onChange={(e) => setTitle(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formEmploymentType">
                                            <Form.Label>Employment Type</Form.Label>
                                            <Form.Control as="select"
                                                value={employmentType}
                                                name="employmentType" 
                                                onChange={(e) => setEmploymentType(e.target.value)} >
                                                    <option value="">
                                                        Select Employment Type
                                                    </option>
                                                    <option value="CONS">
                                                        Consultant
                                                    </option>
                                                    <option value="CONT">
                                                        Contractor
                                                    </option>
                                                    <option value="FT">
                                                        Full-Time
                                                    </option>
                                                    <option value="PT">
                                                        Part-Time
                                                    </option>
                                                    <option value="PD">
                                                        Per diem
                                                    </option>
                                                    <option value="V">
                                                        Volunteer
                                                    </option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formNaics">
                                            <Form.Label>Industry *</Form.Label>
                                            <Form.Control as="select"
                                                value={naicsCodeId}
                                                name="naics" 
                                                onChange={(e) => setNaicsCodeId(e.target.value)}
                                                required >
                                                {naicsOptions.map((option) => {
                                                    return (
                                                        <option key={option.value} value={option.value}>
                                                            {option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formDateOfHire">
                                            <Form.Label>Industry Experience Beginning *</Form.Label>
                                            <Form.Control type="date" 
                                                defaultValue={industryExperience}
                                                name="date" 
                                                onChange={(e) => setIndustryExperience(e.target.value)} 
                                                required />
                                        </Form.Group>
                                        <OperationalJurisdictions 
                                            operationalJurisdictions = {operationalJurisdictions}
                                            setOperationalJurisdictions = {setOperationalJurisdictions}
                                            setUpdate={setUpdate}
                                        />
                                        <Form.Group className="mb-3" controlId="formAddressLine1">
                                            <Form.Label>Address Line 1 *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address Line 1"
                                            value={addressLine1}
                                            name="addressLine1" 
                                            onChange={(e) => setAddressLine1(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formAddressLine2">
                                            <Form.Label>Address Line 2</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address Line 2"
                                                value={addressLine2}
                                                name="addressLine2" 
                                                onChange={(e) => setAddressLine2(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formCity">
                                            <Form.Label>City *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter City"
                                            value={city}
                                            name="city" 
                                            onChange={(e) => setCity(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formStateProvince">
                                            <Form.Label>State/Province *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter State/Province"
                                            value={stateProvince}
                                            name="stateProvince" 
                                            onChange={(e) => setStateProvince(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formPostalCode">
                                            <Form.Label>Postal Code *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Last Name"
                                            value={postalCode}
                                            name="postalCode" 
                                            onChange={(e) => setPostalCode(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formCountry">
                                            <Form.Label>Country *</Form.Label><br />
                                            <ReactCountryDropdown
                                                defaultCountry={country}
                                                onSelect={(e) => setCountry(e.code)}
                                                required />
                                        </Form.Group>
                                        <TelephoneNumber 
                                            telephoneNumber={telephoneNumber} 
                                            setTelephoneNumber={setTelephoneNumber} />
                                        <Form.Group className="mb-3" controlId="formLinkedIn">
                                            <Form.Label>LinkedIn Profile</Form.Label>
                                            <Form.Control type="text" placeholder="Enter LinkedIn Profile"
                                            value={linkedIn}
                                            name="linkedIn" 
                                            onChange={(e) => setLinkedIn(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formLanguage">
                                            <Form.Label>Preferred Language *</Form.Label>
                                            <SelectLanguage 
                                                setLanguage={setPreferredLanguage}
                                                language={preferredLanguage}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formLanguage">
                                            <Form.Label>Additional Languages</Form.Label>
                                            {additionalLanguageMessage ? <Alert key="messageAlert" variant="danger">{additionalLanguageMessage}</Alert> : null}
                                            <SelectLanguage 
                                                setLanguage={setAdditionalLanguage}
                                                language={additionalLanguage}
                                            />
                                            {(additionalLanguage || additionalLanguages.length > 0) &&
                                                <>
                                                    <Form.Control as="select"
                                                        value={additionalLanguageProficiency}
                                                        name="additionalLanguageProficiency" 
                                                        onChange={(e) => setAdditionalLanguageProficiency(e.target.value)}
                                                        style={{marginTop: "15px"}}>
                                                            <option value="">
                                                                Select Proficiency
                                                            </option>
                                                            <option value="B">
                                                                Beginner
                                                            </option>
                                                            <option value="I">
                                                                Intermediate
                                                            </option>
                                                            <option value="A">
                                                                Advanced
                                                            </option>
                                                            <option value="F">
                                                                Fluent
                                                            </option>
                                                    </Form.Control>
                                                    <Button variant="primary" onClick={(e) => handleAddAdditionalLanguage(e)} 
                                                        type="submit" style={{ marginTop: "15px"}} className="Margin-Bottom">
                                                        Add
                                                    </Button>
                                                    {additionalLanguages.map((language) => {
                                                        let uuid = uuidv4();
                                                        let proficiency = "";
                                                        switch (language.proficiency) {
                                                            case "B":
                                                                proficiency = "Beginner";
                                                                break;
                                                            case "I":
                                                                proficiency = "Intermediate";
                                                                break;
                                                            case "A":
                                                                proficiency = "Advanced";
                                                                break;
                                                            case "F":
                                                                proficiency = "Fluent";
                                                                break;
                                                            default:
                                                                proficiency = "Unknown";
                                                                break;
                                                        }
                                                        return (
                                                            <div key={uuid}>{language.language} - {proficiency}{' '}
                                                                <Link onClick={(e) => handleRemoveAdditionalLanguage(e, language)}>
                                                                    [Remove]
                                                                </Link>
                                                            </div>
                                                        );
                                                    })}
                                                </>   
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formProfessionalOrganizations">
                                            <Form.Label>Professional Organizations of Which You Are a Member</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Professional Organizations"
                                            value={professionalOrganizations}
                                            name="professionalOrganizations" 
                                            onChange={(e) => setProfessionalOrganizations(e.target.value)} />
                                        </Form.Group>
                                        <p>
                                            { '* Required' }
                                        </p>
                                        <div className="d-flex flex-wrap">
                                            <div className="p-1">
                                                <Button variant="primary" type="submit" hidden={buttonClicked}>
                                                    Update Profile
                                                </Button>
                                                <Button variant="primary" disabled hidden={!buttonClicked}>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    Loading...
                                                </Button>
                                            </div>
                                            <div className="p-1">
                                                <Button as="a" href="/profile/password" style={{marginLeft: "10px"}}>Change Password</Button>
                                            </div>
                                            <div className="p-1">
                                                <Button as="a" href="/profile/tfa" style={{marginLeft: "10px"}}>Two Factor Authentication</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>     
        </HelmetProvider>
	);
};

export default Profile;
