import { Outlet } from "react-router-dom";
import NavigationBar from "../NavigationBar";
import Alert from 'react-bootstrap/Alert';

const AppLayout = ({isEvaluator, isAdmin, isSuperUser, tfaIsEnabled, userId}) => {
    return <div>
            <NavigationBar 
                isEvaluator={isEvaluator}
                isAdmin={isAdmin}
                isSuperUser={isSuperUser}
                userId={userId}
            />
            { !tfaIsEnabled &&
                <a href="/profile/tfa" className="text-muted"><Alert key="tfaAlert" variant="warning"><center>Please click here to enable two factor authentication.</center></Alert></a>
            }
            <Outlet />
        </div>;
};

export default AppLayout;
