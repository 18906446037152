import { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

const TwofactorAuth = (tfaIsEnabled) => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [secretKey, setSecretKey] = useState("");
    const [qrCodeBase64, setQrCodeBase64] = useState("");
    const [recoveryCodes, setRecoveryCodes] = useState([]);
    const [generateButtonClicked, setGenerateButtonClicked] = useState(false);
    const [enableButtonClicked, setEnableButtonClicked] = useState(false);
    const [disableButtonClicked, setDisableButtonClicked] = useState(false);
    const [confirmCode, setConfirmCode] = useState("");
    const [showEnableConfirmation, setShowEnableConfirmation] = useState(false);
    const [showDisableConfirmation, setShowDisableConfirmation] = useState(false);

    function isEmpty(value) {
        return (value == null || (typeof value === "string" && value.trim().length === 0));
    }

    const handleCloseEnableConfirmation = () => setShowEnableConfirmation(false);
  	const handleShowEnableConfirmation = () => {
        if (isEmpty(confirmCode))
            {
                setAlertVariant("danger");
                setMessage("You must enter a valid code from your authenticator application.");
                window.scrollTo(0, 0);
                return;
            }
            setShowEnableConfirmation(true)
    };
    const handleCloseDisableConfirmation = () => setShowDisableConfirmation(false);
  	const handleShowDisableConfirmation = () => setShowDisableConfirmation(true);


    let handleGenerate = async (e) => {
        e.preventDefault();
        setGenerateButtonClicked(true);
        try 
        {
            let response = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + '/2fa/totp/generate', { withCredentials: true } );
            setAlertVariant("primary");               
            setMessage(response.data.message);
            setSecretKey(response.data.secretKey);
            setQrCodeBase64(response.data.qrCodeBase64);
            setRecoveryCodes(response.data.recoveryCodes);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            setGenerateButtonClicked(false);
        }
    };

    let handleEnable = async (e) => {
        e.preventDefault();
        handleCloseEnableConfirmation();
        setEnableButtonClicked(true);
        try 
        {
            let body = { code: confirmCode }
            let response = await axios.post(process.env.REACT_APP_BASE_AUTH_API_URL + '/2fa/totp/enable', 
                body, { withCredentials: true } );
            window.location.reload(false);
            setAlertVariant("primary");               
            setMessage(response.data.message);
            
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            setEnableButtonClicked(false);
            window.scrollTo(0, 0);
        }
    };

    let handleDisable = async (e) => {
        e.preventDefault();
        setDisableButtonClicked(true);
        try 
        {
            let response = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + '/2fa/totp/disable', { withCredentials: true } );
            window.location.reload(false);
            setAlertVariant("primary");               
            setMessage(response.data.message);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            setDisableButtonClicked(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Two Factor Authentication</title>
            </Helmet>
            <div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Two Factor Authentication</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <div className="Details">
                        {!tfaIsEnabled.tfaIsEnabled &&
                            <div>
                                <p>
                                    Two factor authentication with an authenticator application dramatically improves security.
                                    To enable two factor authentication download a free authenticator application from your phone app 
                                    store scan the QR code.
                                </p>
                                <ul>
                                    <li>Google Authenticator</li>
                                    <li>Authy</li>
                                    <li>Microsoft Authenticator</li>
                                </ul>
                                <p>
                                    <b>Save your recovery codes in a safe place!</b> You will need them to access your account if you lose
                                    access to your device.
                                </p>
                                {!secretKey &&
                                    <>
                                        <Button variant="primary" onClick={(e) => handleGenerate(e)} hidden={generateButtonClicked}>
                                            Continue
                                        </Button>
                                        <Button variant="primary" disabled hidden={!generateButtonClicked}>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </Button>
                                    </>
                                }
                                { secretKey &&
                                    <>
                                        <div className="Margin-Bottom">
                                            You will not be able to access these secrets once you complete the enrollment process.
                                        </div>
                                        <div>
                                            Use the authenticator application to scan the QR code or manually enter the secret key.
                                        </div>
                                        {qrCodeBase64 ? 
                                            <img style={{width: "40%", height: "auto"}}
                                                alt="QR Code"
                                                src={`data:image/png;base64,${qrCodeBase64}`}/>: ''
                                        }
                                        <div className="Margin-Bottom">
                                            Secret Key: {secretKey}
                                        </div>
                                        <div className="Margin-Bottom">
                                            <b>Save your recovery codes in a safe place!</b> Each recovery code can be used once in place of a code from your 
                                            authenticator application if you lose access to your device. You will not be able to access your account
                                            without a code from your authenticator application or a recovery code.
                                        </div>
                                        <div className="Margin-Bottom">
                                            Recovery Codes:
                                            { recoveryCodes.map((recoveryCode) => {
                                                return(
                                                    <div key={recoveryCode}>
                                                        {recoveryCode}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div>
                                            Enter a code from your authenticator application to confirm it.
                                        </div>
                                        
                                            <Form.Group className="mb-3" controlId="formCode">
                                                <Form.Control type="text" placeholder="Enter Code"
                                                value={confirmCode}
                                                name="confirmCode" 
                                                onChange={(e) => setConfirmCode(e.target.value)} 
                                                required />
                                            </Form.Group>
                                            <Button variant="primary" onClick={(e) => handleShowEnableConfirmation(e)} hidden={enableButtonClicked}>
                                                Enable Two Factor Authentication
                                            </Button>
                                            <Button variant="primary" disabled hidden={!enableButtonClicked}>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                        
                                    </>
                                }
                            </div>
                        }
                        {tfaIsEnabled.tfaIsEnabled && 
                            <div>
                                <div className="Margin-Bottom">
                                    Two factor authentication with an authenticator application dramatically improves security.
                                </div>
                                <div className="Margin-Bottom">
                                    You cannot access the secrets once you have completed the enrollment process. 
                                    To access your secrets you must disable two factor authentication then complete the enrollment process again.
                                </div>
                                <Button variant="danger" onClick={(e) => handleShowDisableConfirmation(e)} hidden={disableButtonClicked}>
                                    Disable Two Factor Authentication
                                </Button>
                                <Button variant="danger" disabled hidden={!disableButtonClicked}>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </Button>
                            </div>
                        }
                    </div>
                    <Modal show={showEnableConfirmation} onHide={handleCloseEnableConfirmation}>
                        <Modal.Header closeButton>
                            <Modal.Title>Enable Two Factor Authentication</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                You will need the recovery codes to access your account if you lose access to your device.
                            </p>
                            <p>
                                Are you certain you have saved the recovery codes in a safe place?
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEnableConfirmation}>
                            No
                        </Button>
                        <Button variant="danger" onClick={(e) => handleEnable(e)}>
                            Yes
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showDisableConfirmation} onHide={handleCloseDisableConfirmation}>
                        <Modal.Header closeButton>
                            <Modal.Title>Disable Two Factor Authentication</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you certain you want to disable two factor authentication?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDisableConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={(e) => handleDisable(e)}>
                            Disable
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>   
        </HelmetProvider>
    );
};

export default TwofactorAuth;
