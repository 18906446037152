import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const DefinedExpiration = ({qualificationExpirationTypeId, expirationDate, setExpirationDate}) => {

    const [showFields, setShowFields] = useState(false);

    useEffect(() => {
        if (Number(qualificationExpirationTypeId) === 2)
        {
            setShowFields(true);
        }
        else
        {
            setShowFields(false);
        }
    }, [qualificationExpirationTypeId]);

    return (
        <div>
            { showFields &&
                <>
                    <Form.Group className="mb-3" controlId="formDate">
                        <Form.Label>Date of Expiration *</Form.Label>
                        <Form.Control type="date" 
                            defaultValue={expirationDate}
                            name="date" 
                            onChange={(e) => setExpirationDate(e.target.value)} 
                            required />
                    </Form.Group>
                </>
            }
        </div>
    );
};

export default DefinedExpiration;
