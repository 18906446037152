import { useEffect, useState } from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table'

function formatDate(timestampString) 
{
    if (timestampString.startsWith("0001-01-01") || timestampString.startsWith("9999-12-31"))
    {
        return ""
    }
    const options = { year: "numeric", month: "long", day: "numeric"}
    return new Date(timestampString).toLocaleDateString(undefined, options)
}

const ExpirationReport = ({organizationUuid,setLoading,setNoData}) => {  

    const [completedQualifications, setCompletedQualifications] = useState([]);
    const [incompleteQualifications, setIncompleteQualifications] = useState([]);
	
	useEffect(() => {
        const fetchData = async () => {
            try
            {
                if (organizationUuid)
                {
                    setLoading(true);
                    let response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/report/organization/expiration/" + organizationUuid, 
                        { withCredentials: true });
                    response.data.forEach((q) =>
                        {
                            if(q.expirationDate.startsWith("0001-01-01"))
                            {
                                q.expirationDate = "9999-12-31T23:59:59Z"
                            }
                        }
                    );
                    let sortedQualifications = response.data.sort(function(a,b){
                        return new Date(b.expirationDate) - new Date(a.expirationDate);
                    });
                    let descendingQualifications = sortedQualifications.reverse();
                    let completedQualifications = descendingQualifications.filter(x => {
                        return x.isComplete
                    });
                    setCompletedQualifications(completedQualifications);
                    let incompleteQualifications = descendingQualifications.filter(x => {
                        return !x.isComplete
                    });
                    setIncompleteQualifications(incompleteQualifications);
                    setLoading(false);
                    if (completedQualifications.length < 1 && incompleteQualifications.length < 1)
                    {
                        setNoData(true);
                    }
                }
            }
            catch (error)
            {
                console.log(error)
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationUuid]);

	return (
		<Table stripped="true" bordered="true" hover size="sm"> 
            <thead> 
                <tr> 
                    <th>Surname</th> 
                    <th>Given Name</th> 
                    <th>Email</th> 
                    <th>Qualification</th> 
                    <th>Version</th> 
                    <th>Status</th>
                    <th>Expiration Date</th>
                    <th>In Portfolio</th>
                </tr> 
            </thead> 
                <tbody> 
                    {completedQualifications.length > 0 &&
                        completedQualifications.map((q) => {
                            return (
                                <tr 
                                    key={q.holderUuid}
                                    onClick={() => window.open("/qualification/status?organizationUuid=" + organizationUuid + "&qualificationVersionUuid=" + q.qualificationVersionUuid + "&holderUuid=" + q.holderUuid)}
                                >
                                    <td>{q.user.surname}</td> 
                                    <td>{q.user.givenName}</td> 
                                    <td>{q.user.email}</td> 
                                    <td>{q.qualificationName}</td> 
                                    <td>{q.qualificationVersionName}</td> 
                                    <td>{q.status}</td>
                                    <td>{q.isComplete ? formatDate(q.expirationDate) : ""}</td>
                                    <td>{q.isRemoved ? "No" : "Yes"}</td>
                                </tr>
                            )
                        })
                    }
                    {incompleteQualifications.length > 0 &&
                        incompleteQualifications.map((q) => {
                            return (
                                <tr 
                                    key={q.holderUuid}
                                    onClick={() => window.open("/qualification/status?organizationUuid=" + organizationUuid + "&qualificationVersionUuid=" + q.qualificationVersionUuid + "&holderUuid=" + q.holderUuid)}
                                >
                                    <td>{q.user.surname}</td> 
                                    <td>{q.user.givenName}</td> 
                                    <td>{q.user.email}</td> 
                                    <td>{q.qualificationName}</td> 
                                    <td>{q.qualificationVersionName}</td> 
                                    <td>{q.status}</td>
                                    <td>{q.isComplete ? formatDate(q.expirationDate) : ""}</td>
                                    <td>{q.isRemoved ? "No" : "Yes"}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
        </Table>
	);
}

export default ExpirationReport;