import { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ReactCountryDropdown from "react-country-dropdown";
import Alert from 'react-bootstrap/Alert';
import {v4 as uuidv4} from 'uuid';
import { Link } from 'react-router-dom';

const OperationalJurisdictions = ({ operationalJurisdictions, setUpdate }) => {

    const [country, setCountry] = useState("US");
    const [stateProvince, setStateProvince] = useState("");
    const [classification, setClassification] = useState("");
    const [message, setMessage] = useState("");

    function jurisdictionExists(jurisdiction) {
        let match = operationalJurisdictions.filter((j) =>
            j.country === jurisdiction.country &&
            j.stateProvince === jurisdiction.stateProvince &&
            j.rural === jurisdiction.rural
        );
        let exists = false;
        if (match.length > 0)
        {
            exists = true;
        }
        return exists;
    }

    let handleAddJurisdiction = async (e) => {
        e.preventDefault();
        setMessage("");
        if (!country)
        {
            setMessage("A country is required.");
            return;
        }
        if (!stateProvince)
        {
            setMessage("A state or province is required.");
            return;
        }
        if (!classification)
        {
            setMessage("A classification is required.");
            return;
        }
        let ruralBool = true;
        if (classification === "U")
        {
            ruralBool = false;
        }
        let newJurisdiction = { 
            country: country,
            stateProvince: stateProvince,
            rural: ruralBool
        };
        
        if(jurisdictionExists(newJurisdiction))
        {
            return;
        }
		operationalJurisdictions.push(newJurisdiction);
        setStateProvince("");
        setClassification("");
        setUpdate(true);
	}

    let handleRemoveJurisdiction = async (e, jurisdiction) => {
        e.preventDefault();
		const index = operationalJurisdictions.indexOf(jurisdiction);
        if (index > -1) {
            operationalJurisdictions.splice(index, 1);
            setUpdate(true);
        }
	}

    return (
        <Form.Group className="mb-3" controlId="formJurisdiction">
            <Form.Label>Operational/Practice Jurisdictions</Form.Label><br />
            {message ? <Alert key="messageAlert" variant="danger">{message}</Alert> : null}
            <ReactCountryDropdown
                defaultCountry="US"
                onSelect={(e) => setCountry(e.code)} /><br />
            <Form.Control type="text" placeholder="Enter State/Province"
                value={stateProvince}
                name="stateProvince" 
                onChange={(e) => setStateProvince(e.target.value)} 
                style={{ marginBottom: "10px" }} />
            <Form.Control as="select"
                value={classification}
                name="classification" 
                onChange={(e) => setClassification(e.target.value)}>
                    <option value="">
                        Select Classification
                    </option>
                    <option value="R">
                        Rural
                    </option>
                    <option value="U">
                        Urban
                    </option>
            </Form.Control>
            { operationalJurisdictions.length < 1 &&
                <Button variant="primary" onClick={(e) => handleAddJurisdiction(e)} 
                    type="submit" style={{ marginTop: "15px"}}>
                    Add
                </Button>
            }
            { operationalJurisdictions.length > 0 &&
                <Button variant="primary" onClick={(e) => handleAddJurisdiction(e)} 
                    type="submit" style={{ marginTop: "15px"}} className="Margin-Bottom">
                    Add
                </Button>
            }
            {operationalJurisdictions.map((jurisdiction) => {
                let uuid = uuidv4();
                let classification = "Rural";
                if (!jurisdiction.rural)
                {
                    classification = "Urban";
                }
                return (
                    <div key={uuid}>{jurisdiction.stateProvince}, {jurisdiction.country} - {classification}{' '}
                        <Link onClick={(e) => handleRemoveJurisdiction(e, jurisdiction)}>
                            [Remove]
                        </Link>
                    </div>
                );
            })}
        </Form.Group>
    );
}

export default OperationalJurisdictions;