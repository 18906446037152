import { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button';

import SelectOrganization from '../../SelectOrganization';

const OrganizationSearch = () => {  
	
	const [organization, setOrganization] = useState("");
    const [organizationsInput, setOrganizationsInput] = useState("");

	return (
		<HelmetProvider>
			<Helmet>
					<title>{ process.env.REACT_APP_APP_NAME } - Organizations</title>
			</Helmet>
			<div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Organizations</h3>
                    <SelectOrganization 
                        setOrganization={setOrganization}
                        organizationsInput={organizationsInput}
                        setOrganizationsInput={setOrganizationsInput}
                    />
                    { organization &&
                        
                        <div className="Details Margin-Bottom">
                            <h5 className="mb-1">{organization.name}</h5>
                            { organization.description &&
                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                    Description: {organization.description}
                                </div>
                            }
                            <div>
                                Telephone Number: {organization.telephoneNumber}
                            </div>
                            <div>
                                {organization.addressLine1}
                            </div>
                            { organization.addressLine2 &&
                                <div>
                                    {organization.addressLine2}
                                </div>
                            }
                            <div>
                                {organization.city}, {organization.stateProvince}
                            </div>
                            <div>
                                {organization.country}
                            </div>
                            <div className="Margin-Bottom">
                                Affiliation approval is required to affiliate: 
                                {organization.affiliationApprovalRequired ? " Yes" : " No"}
                            </div>
                            <div className="d-flex flex-wrap">
                                <div className="p-1">
                                    <Button as="a" href={"/organization/edit?organizationUuid=" + organization.uuid} 
                                        style={{ marginRight: "10px" }}>
                                        Edit
                                    </Button>
                                </div>
                                <div className="p-1">
                                    <Button as="a" href={"/qualification/list?organizationUuid=" + organization.uuid} 
                                        style={{ marginRight: "10px" }}>
                                        Qualifications
                                    </Button>
                                </div>
                                <div className="p-1">
                                    <Button as="a" href={"/requirement/list?organizationUuid=" + organization.uuid} 
                                        style={{ marginRight: "10px" }}>
                                        Requirements
                                    </Button>
                                </div>
                                <div className="p-1">
                                    <Button as="a" href={"/evaluators?organizationUuid=" + organization.uuid} 
                                        style={{ marginRight: "10px" }}>
                                        Evaluators
                                    </Button>
                                </div>
                                <div className="p-1">
                                    <Button as="a" href={"/administrators?organizationUuid=" + organization.uuid} 
                                        style={{ marginRight: "10px" }}>
                                        Administrators
                                    </Button>
                                </div>
                                <div className="p-1">
                                    <Button as="a" href={"/administer?organizationUuid=" + organization.uuid} 
                                        style={{ marginRight: "10px" }}>
                                        Affiliations
                                    </Button>
                                </div>
                                <div className="p-1">
                                    <Button as="a" href={"/organization/report?organizationUuid=" + organization.uuid + "&report=Expiration"} 
                                        style={{ marginRight: "10px" }}>
                                        Report
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>   
		</HelmetProvider>
	);
}

export default OrganizationSearch;